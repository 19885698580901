
<template>
  <el-tabs
    type="border-card"
    class="address-tab"
    v-model="selTabName"
    v-if="!isHidden"
  >
    <el-tab-pane
      v-for="(item, index) in tabTitle"
      :key="index"
      :label="item.label"
      :name="item.name"
    >
      <div
        v-for="(items, indexs) in getCitys"
        :key="indexs"
        class="address-item"
        @click="selAddress(item.name, items, index)"
        v-loading="addressLoading"
      >
        {{ items.name }}
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    isHidden: {
      type: Boolean,
      default: true,
    },
    address: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tabTitle: [{ name: "province", label: "选择新地址" }],
      selTabName: "",
      cityList: [],
      addressLoading: false,
      addressInfo: {},
      street: true,
    };
  },
  watch: {
    isHidden(e) {
      if (!e) {
        this.selTabName = "province";
        if (this.address.province) {
          this.addressInfo = {
            ...Object.assign(this.addressInfo, this.address),
          };
          this.tabTitle = [];
          for (var key in this.address) {
            this.tabTitle.push({ name: key, label: this.address[key].name });
          }
        }
        var length = this.tabTitle.length;
        if (length > 1) {
          this.selTabName = this.tabTitle[length - 1].name;
        }
      } else {
        this.tabTitle = [{ name: "province", label: "请选择" }];
        this.selTabName = "";
        this.cityList = [];
        this.addressInfo = {};
      }
    },
    
  },
  computed: {
    ...mapGetters(["getProvince"]),
    getStreetData() {
      return this.$store.state.street;
    },
    getCitys() {
      var cityArray = [];
      var code = 0;
      switch (this.selTabName) {
        case "province":
          var provinceList = this.getProvince;
          if (provinceList.length == 0) {
            this.getProvinceList();
          }
          cityArray = provinceList;
          break;
        case "city":
          code = this.addressInfo.province.code;
          var city = this.$store.getters.getCity(code);
          if (!city) {
            this.getCityList(code);
          }
          if (city) {
            cityArray = city.data;
          }
          break;
        case "district":
          code = this.addressInfo.city.code;
          var area = this.$store.getters.getDistrict(code);
          if (!area) {
            this.getDistrictList(code);
          }
          if (area) {
            cityArray = area.data;
          }
          break;
        case "street":
          code = this.addressInfo.district.code;
          var streets = this.$store.getters.getStreet(code);
          if (!streets) {
            this.getStreetList(code);
          }
          if (streets) {
            cityArray = streets.data;
            
            if (cityArray.length == 0) {
              this.update(1);
            }
          }

          break;
      }
      return cityArray;
    },
  },
  methods: {
    ...mapActions(["setProvince", "setCity", "setDistrict", "setStreet"]),
    selAddress(key, item, index) {
      this.addressInfo[key] = item;
      this.tabTitle[index].label = item.name;
      //重选清空下级信息
      let length = this.tabTitle.length;
      if (length - 1 > index) {
        this.tabTitle.splice(index + 1, length - 1);
      }
      switch (key) {
        case "province":
          this.selTabName = "city";
          this.tabTitle.push({
            label: "请选择",
            name: "city",
          });
          break;
        case "city":
          this.selTabName = "district";
          this.tabTitle.push({
            label: "请选择",
            name: "district",
          });
          break;
        case "district":
          this.selTabName = "street";
          this.tabTitle.push({
            label: "请选择",
            name: "street",
          });
          break;
        case "street":
          this.update();
          break;
      }
    },
    getProvinceList() {
      this.loading = true;
      this.$axios.get("/api/UserAddress/GetProvince", "").then((res) => {
        if (res.data.length > 0) {
          this.setProvince(res.data);
          this.loading = false;
        } else {
          this.notifyError("未获取到省份信息");
          this.loading = false;
        }
      });
    },
    getCityList(code) {
      this.addressLoading = true;
      this.$axios
        .get("/api/UserAddress/GetCity", {
          params: { code: code },
        })
        .then((res) => {
          if (res.data) {
            this.setCity({ code: code, data: res.data });
            this.addressLoading = false;
          }
        });
    },
    getDistrictList(code) {
      this.addressLoading = true;
      this.$axios
        .get("/api/UserAddress/getCounty", {
          params: { code: code },
        })
        .then((res) => {
          if (res.data) {
            this.setDistrict({ code: code, data: res.data });
            this.addressLoading = false;
          }
        });
    },
    getStreetList(code) {
      this.addressLoading = true;
      this.$axios
        .get("/api/UserAddress/getTown", {
          params: { code: code },
        })
        .then((res) => {
          if (res.data) {
            this.setStreet({ code: code, data: res.data });
            if (res.data.length == 0) {
              this.street = false;
            }

            this.addressLoading = false;
          }
        });
    },
    update(val=0) {
      if (this.addressInfo.street && val>0) {
        delete this.addressInfo.street;
      }

      this.$emit("change", this.addressInfo);
    },
  },
};
</script>

<style lang="scss">
.address-tab {
  .el-tab-pane {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;
    margin-left: 10px;
    .address-item {
      width: 100px;
      text-align: center;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #999999;
      &:hover {
        text-decoration: underline;
        color: #ff5933;
      }
    }
  }
}
</style>>