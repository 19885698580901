<template>      
    <el-select v-model="value"  placeholder="请选择" clearable class="autowidth-select" @change="(value)=>selectMyAddress(value)" >
        <template slot="prefix"> 
        {{(options.find(s => s.value === value) || {}).label}}
      </template>
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
 export default {
    props: {
        isHidden: {
            type: Boolean,
            default: true,
        },
        address: {
            type: Object,
            default() {
                return {};
            },
        }
     },
    data() {
        return {
          options: [],
          value: "",
          loading: true,
          MyAddressData:[]
        }
      },
      mounted(){
        this.getMyAddress();       
      },
      watch: {
        isHidden(e) {
            this.MyAddressData=[];
            this.options=[];
         },       
    },
      methods: {   
        getMyAddress() {          
            this.loading = true;
            this.MyAddressData=[];
            this.options=[];
            this.$axios
                .get("/api/UserAddress/GetListAddress", "")
                .then((res) => {
                    if(res && res.data)
                    {
                        this.MyAddressData=res.data;  
                        this.MyAddressData.sort(function(a,b){ return b.IsDefault-a.IsDefault;});
                        this.MyAddressData.forEach(a => {
                            var labelName=a.Name+"  "+a.Province+a.City+a.District;
                            if(a.Town)
                            {
                              labelName=labelName+a.Town;
                            }

                            if(a.Address)
                            {
                              labelName=labelName+a.Address;
                            }

                           // console.log(labelName.length);
                            if(labelName.length>25)
                            {
                                labelName=labelName.substring(0,25)+"...";
                            }
                            this.options.push({value:a.AddressId+"",label:labelName});
                        });
                        if(this.address.province && this.MyAddressData && this.MyAddressData.length>0)
                        {
                            var r=localStorage.getItem("MyAddressSelected");
                            this.value=r;
                        }
                        else{
                            localStorage.setItem("MyAddressSelected", "");
                        }
                       
                    }             
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
            },
            selectMyAddress(e){   
                //console.log(e);                
                var d= this.MyAddressData.find(n=>n.AddressId==e);
                if(d){
                    localStorage.setItem("MyAddressSelected", e);
                    var result={
                        "province":{"name":d.Province,"code":d.ProvinceCode+""},
                        "city":{"name":d.City,"code":d.CityCode+""},
                        "district":{"name":d.District,"code":d.DistrictCode+""}                   
                    };
                    if(d.Town)    
                    {
                        result.street={"name":d.Town,"code":d.TownCode+""};
                    }
                   // console.log(result);
                    this.$emit("change", result);
                }
            } 
        },
       

    }
  </script>

<style scoped >
.autowidth-select {  
  width: 370px;
  min-width: 180px;
}

.autowidth-select >>>.el-input__prefix {
  position: relative !important;
  left: 0;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  padding: 0 5px;
  height: 40px;
  line-height: 40px;
  visibility: hidden;
}

.autowidth-select >>> input {
  position: absolute;
  padding-left:5px;
}

</style>