<template>
  <div>
    <!-- 登录提示 -->
    <div class="login-notic" v-if="!getLogInState && showTips">
      <span class="title">为方便您购买，请提前登录</span>
      <div class="btn-login" @click="goLogin">立即登录</div>
      <i class="icon i-close" @click="showTips = false"></i>
    </div>

    <div class="goods-wrapper" v-if="goodsId > 0 && goods.id">
      <div class="wrap">
        <!-- 轮播图 -->
        <div class="banner-wrap" v-if="banners.length > 0">
          <el-carousel
            height="560px"
            width="560px"
            indicator-position="none"
            :initial-index="bannerIndex"
            :autoplay="autoplay"
            @change="bannerChange"
            ref="carousel"
          >
            <el-carousel-item
              class="swiper-item"
              v-for="(item, index) in banners"
              :key="index"
            >
              <img class="cover" :src="item" mode="aspectFill" />
            </el-carousel-item>
          </el-carousel>
          <!-- 轮播缩略图 -->
          <div>
            <div class="bannerImage">
              <a
                href="javascript:;"
                class="arrow-prev"
                :class="{ disabled: bannerNext }"
                @click="bannerClick(false)"
              >
                <i class="sprite-arrow-prev"></i>
              </a>
              <a
                href="javascript:;"
                class="arrow-next"
                :class="{ disabled: !bannerNext }"
                @click="bannerClick(true)"
              >
                <i class="sprite-arrow-next"></i>
              </a>
              <div>
                <ul id="wrapper-box">
                  <li
                    v-for="(url, imgIndex) in banners"
                    :key="imgIndex"
                    class="bannerli"
                  >
                    <img
                      :src="url"
                      width="50"
                      height="50"
                      :class="{ 'img-hover': imgIndex == bannerIndex }"
                      @mouseover="bannerMouseOver(imgIndex)"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- 商品详情 -->
        <div class="goods-main">
          <div class="goods-name"  :title="title">
            <span
              :class="['product-tag', { isjd: goods.goodsFromType == 1 }]"
              >{{ goods.goodsFromType == 0 ? "严选" : "智选" }}</span
            >{{ title }}
          </div>
          <div class="goods-desc">{{ goods.introduction }}</div>
          <div class="sku-list">
            <div class="sku-wrap" v-for="(item, index) in specs" :key="index">
              <div class="title">商品编码：{{ skuId }}</div>
              <div class="items">
                <div v-for="(attr, attrIndex) in item.items" :key="attrIndex">
                  <div
                    :class="['item', { active: attrIndex == skuIndexs[index] }]"
                    @click="switchSku(index, attrIndex)"
                  >
                    <el-image
                      v-if="index == specs.length - 1"
                      class="thumb"
                      :src="getIcon(attrIndex)"
                      mode="aspectFill"
                      lazy
                      ><div slot="error" class="image-slot">
                      </div>
                    </el-image>
                    <span class="name elip" :title="attr">{{ attr }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="current-goods">
            <div class="subtotal">
              <div class="address" v-if="goods.goodsFromType == 1">
                <el-row>
                  <el-col :span="3"> <span>配送至：</span></el-col>
                  <el-col :span="20">
                    <span @click="addressHidden = !addressHidden" class="text">
                      {{ mainAddress ? mainAddress : "--请选择--"
                      }}<i
                        :class="
                          addressHidden
                            ? 'el-icon-arrow-down'
                            : 'el-icon-arrow-up'
                        "
                      ></i></span
                    ><span v-if="showSkuState">{{ getSkuStateDec }}</span>
                    <div class="usually-address" v-if="getLogInState && !addressHidden">常用地址：
                      <usuallyAddress  
                      :isHidden="addressHidden"
                      :address="shippingAddress"
                      @change="mainAddressChange">
                      </usuallyAddress>
                    </div>
                    <cityPicker
                      :isHidden="addressHidden"
                      :address="shippingAddress"
                      @change="mainAddressChange"
                    ></cityPicker>
                  </el-col>
                </el-row>
              </div>
              <div v-if="promiseStatus == true">
                <el-row>
                  <el-col> 
                    <div class="shipInfo" v-html="promiseDes"></div>                   
                  </el-col>
                </el-row>
              </div>
              <div class="price">
                <div class="priceLeft">
                  <dl><span style="color: #999">市场价:</span></dl>
                  <dl></dl>
                </div>
                <div class="priceRight">
                  <dl class="originalPrice" v-if="showOriginalPrice">
                    {{ originalPrice.toFixed(2) }}
                  </dl>
                  <dl>
                    <span
                      :class="showOriginalPrice ? 'priceNum' : 'originalPrice'"
                      >{{ currentPrice.toFixed(2) }}</span
                    ><span>{{ $currency }}</span>
                    <span class="originalName" v-if="showOriginalPrice"
                      >会员价</span
                    >
                  </dl>
                </div>
              </div>
              <div class="dec">
                <div v-if="goods.limitState > 0" class="item">
                  <span class="red_bg">限购</span>
                  <span class="text">{{ limitDec(goods.limitState) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <el-input-number
              v-model="payNum"
              controls-position="right"
              @change="handleChange"
              :min="minPayNum"
              :max="skus[this.skuIndex].sellout"
              label="描述文字"
              class="addnum"
            ></el-input-number>
            <el-tooltip
              content="建议选择配送地址查询该地区是否有货"
              placement="top"
              :disabled="showSkuState"
            >
              <button
                v-loading="btnLoading"
                :class="['btn-primary', { disabled: sellout || !skuState }]"
                @click="putToCart"
                ref="putCart"
              >
                {{
                  sellout
                    ? skus[skuIndex].state > 0
                      ? "该商品已售罄"
                      : "商品已下架"
                    : btnName
                }}
              </button>
            </el-tooltip>
            <button
              :class="['btn-gray', { active: liked }]"
              @click="switchLike"
              v-loading="collectLoading"
            >
              <svg
                t="1637313110632"
                class="icon"
                viewBox="0 0 1086 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2540"
                width="20"
                height="20"
              >
                <path
                  d="M537.351758 0l204.644848 283.306667 332.675879 107.085575-206.196364 282.189576 0.930909 349.494303-332.055272-108.916363-332.117334 108.916363 0.930909-349.494303L0 390.361212l332.644848-107.054545L537.382788 0z m183.202909 460.924121l-186.585212 100.28994-186.523152-100.28994-29.385697 54.675394 215.939879 116.053333 215.908848-116.053333-29.354666-54.675394z"
                  fill="#FDAD4A"
                  p-id="2541"
                ></path>
              </svg>
              <span>{{ liked ? "已收藏" : "喜欢" }}</span>
            </button>
          </div>
          <div class="tip">温馨提示：非质量问题不支持退换货</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import cityPicker from "@/components/user/picker/address-tabs.vue";
  import usuallyAddress from "@/components/user/picker/usually-address.vue";
  export default {
    components: {
      cityPicker,
      usuallyAddress,
    },
    props: {
      goodsId: Number,
      goodInfo: Object,
      goodskuId:{
        Type:Number,
        default:0
      }
    },
    data() {
      return {
        goods: {},
        skus: [],
        skuIndex: 0,
        skuIndexs: [],
        skuDisabled: false,
        bannerIndex: 0,
        autoplay: true,
        showTips: true,
        liked: false,
        magnifier: {
          show: false,
          top: 0,
          left: 0,
        },
        hasLogin: false,
        bannerNext: false,
        btnLoading: false,
        scrollLeft: 0,
        btnName: "加入购物车",
        isPay: false,
        collectLoading: false,
        payNum: 0,
        minPayNum: 1,
        shippingAddress: {},
        addressHidden: true,
        skuState: false,
        showSkuState: true, //是否显示货物状态
        inventoryStatus:false,
        promiseStatus:false,
        promiseDes:"",
      };
    },
    watch: {
      goodInfo(e) {
        if (e.id) {
          this.bannerIndex = 0;
          this.liked = e.isLike > 0;
          this.findSkus();
          var address = localStorage.getItem("shippingAddress");
          if (address) {
            this.shippingAddress = JSON.parse(address);
          }
          this.skuState = true;
          this.showSkuState = true;
          this.addCarList = [];
        } else {
          this.goods = {};
          this.selTabName = "";
        }
      },
      btnLoading(e) {
        var btn = this.$refs.putCart;
        if (btn) {
          btn.disabled = e;
        }
      },
      //只有添加监听才能刷新数据
      inventoryDes() {},
      skuIndex(e) {
        if (e) {
          this.btnName = "加入购物车";
          this.isPay = false;
        }
      },
    },
    computed: {
      ...mapGetters(["getUser", "getUserId"]),
      getSkuStateDec() {
        return this.skuState ? "有货" : "无货";
      },
      mainAddress() {
        if (this.shippingAddress.district) {
          let address =
            this.shippingAddress.province.name +
            " " +
            this.shippingAddress.city.name +
            " " +
            this.shippingAddress.district.name;
          if (this.shippingAddress.street) {
            address += " " + this.shippingAddress.street.name;
          }
          return address;
        }
        return "";
      },
      limitDec() {
        return (val) => {
          var name = "";
          switch (val) {
            case 1:
              name = "每人一件";
              break;
            case 2:
              name = "一天一件";
              break;
            case 3:
              name = "一天一次";
              break;
            case 4:
              name = "每人一次";
              break;
          }
          return name;
        };
      },
      specs() {
        const attrs = this.skus.map((e) => e.goodsAttr);
        const keys = attrs[0].map((e) => e.key);
        const specs = [];
        let that = this;
        keys.forEach((key) => {
          const items = [];
          attrs.forEach((attr) => {
            attr.forEach((obj) => {
              if (obj.key == key) items.push(obj.value);
            });
          });
          specs.push({
            name: key,
            items: Array.from(items),
          });
          that.skuIndexs.push(0);
        });
        return specs;
      },
      banners() {
        return this.skus[this.skuIndex].imgsPath.split(",");
      },
      currentPrice() {
        return this.skus[this.skuIndex].price;
      },
      originalPrice() {
        return this.skus[this.skuIndex].onlinePrice;
      },
      fullName() {
        let name = this.goods.name;
        if (this.goods.skuType == 1) {
          this.specs.forEach((spec, i) => {
            name += " " + spec.items[this.skuIndexs[i]];
          });
        }
        return name;
      },
      sellout() {
        return this.skus[this.skuIndex].sellout <= 0 || this.skuDisabled;
      },
      skuId() {
        return this.skus[this.skuIndex].id;
      },
      title() {
        return this.skus[this.skuIndex].title;
      },
      inventoryDes() {
        if (this.goods.goodsFromType == 1) {
          this.getInventory();
        }
        return "";
      },
      getLogInState() {
        return this.$store.getters.getUser;
      },
      showOriginalPrice() {
        return (
          //不区分严选和智选，全部都显示会员价和市场价
          // this.goods.goodsFromType == 1 &&
          this.originalPrice > 0 &&
          this.currentPrice < this.originalPrice
        );
      },
    },
    activated() {
      this.skuIndexs = [];
      this.skuIndex = -1;
      this.skuDisabled = false;
      // this.hasLogin = false;
      // if (this.getUser) {
      //   this.hasLogin = true;
      // }
    },
    methods: {
      ...mapActions(["setShowLogin", "unshiftShoppingCart"]),
      getInventory() {
        var res = -1;
        this.showSkuState = false;
        if (this.skus[this.skuIndex] && this.shippingAddress.district) {
          this.showSkuState = true;
          this.skuState = false;
          this.btnLoading = true;
          const skuId = this.skus[this.skuIndex].modelCodeNumber;
          const address = this.shippingAddress;
          const stoctJson = [{ skuId: skuId, num: this.payNum }];
          const params = {
            provinceCode: address.province.code,
            cityCode: address.city.code,
            districtCode: address.district.code,
            townCode: address.street ? address.street.code : 0,
            jdSkuJson: JSON.stringify(stoctJson),
          };
          //触发前，先初始化参数值，避免显示的为缓存数据         
          this.promiseDes = "";
          this.promiseStatus = false;

          this.$axios
            .post("/api/GoodsOrder/GetJdGoodInventory", this.qs.stringify(params))
            .then((res) => {
              if (res.data.IsInventory) {
                this.skuState = true;              
              }
              //物流预计配送时间参数赋值
              this.promiseStatus = res.data.IsPromise;
              this.promiseDes = res.data.Promise;
              
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
        }
        return res;
      },
      //地址选择
      mainAddressChange(e) {
       // console.log(JSON.stringify(e));
        this.shippingAddress = {};
        if (e) {
          localStorage.setItem("shippingAddress", JSON.stringify(e));
        }
        this.shippingAddress = {
          ...Object.assign(this.shippingAddress, e),
        };
        this.addressHidden = true;
      },
      handleChange(value) {
        this.payNum = value;
      },
      handleFun() {
        var scroll = document.getElementById("wrapper-box");
        this.scrollLeft = scroll.scrollLeft;
      },
      bannerClick(val) {
        if (val === this.bannerNext) {
          return;
        }
        let addScroll = 50;
        if (!val) {
          addScroll = -50;
        }
        this.bannerNextScroll(addScroll);
        this.bannerNext = val;
      },
      //水平滚动效果
      bannerNextScroll(addScroll) {
        var scroll = document.getElementById("wrapper-box");
        if (!scroll) {
          return;
        }
        let scrollLeft = scroll.scrollLeft;
        if (scrollLeft < 0) {
          return;
        }
        var timer = setInterval(function () {
          scrollLeft += addScroll;
          scroll.scrollLeft = scrollLeft;
          if (scrollLeft >= 500 || scrollLeft < 50) {
            clearInterval(timer);
            return;
          }
        }, 10);
      },
      //展示选中轮播图
      bannerMouseOver(val) {
        this.bannerIndex = val;
        this.$refs.carousel.setActiveItem(val);
      },
      findSkus() {
        this.payNum = 1;
        this.minPayNum = 1;
        this.skuIndex = 0;
        this.btnName = "加入购物车";
        this.isPay = false;
        const data = this.goodInfo;
        const { id, name, introduction, banners, goodsFromType } = this.goodInfo;
        this.goods = { id, name, introduction, banners, goodsFromType };
        let list = data.list.sort((a,b)=>{ return a.id-b.id});
        this.skus = list;
        this.attrs = list.map((e) => e.goodsAttr);
        if(this.goodskuId>0){
          let index= list.findIndex(i=>i.id==this.goodskuId)
          this.switchSku(index, index);
        }
        else{
          this.switchSku(0, 0);
        }
        let routeSkuId = this.$route.query.skuId; 
        if(!routeSkuId)
        {
           routeSkuId = Number(this.$route.query.search);
        }
        if (routeSkuId) {
          const skuId = routeSkuId;
          const attrIndex = list.findIndex((item) => item.id == skuId);
          this.skuIndexs[0] = attrIndex;
          this.$set(this.skuIndexs, 0, attrIndex);
        }
      },
      getIcon(index) {
        return this.skus[index].icon; //this.skus[index].bannerUrls.split(",")[0];
      },
      switchSku(index, attrIndex) {
        this.skuIndexs[index] = attrIndex;
        this.$set(this.skuIndexs, index, attrIndex);
        //获取当前每级规格的值
        // const values = [];
        // this.specs.forEach((spec, i) => {
        //   values.push(spec.items[this.skuIndexs[i]]);
        // });
        // const skuIndex = this.attrs.findIndex((items) => {
        //   let count = 0;
        //   values.forEach((val, i) => {
        //     if (val == items[i].value) {
        //       count++;
        //     }
        //   });
        //   return count == values.length;
        // });
        const skuIndex = attrIndex;
        // 找到skuIndex，找不到则不改变skuIndex，表现为库存为空
        this.skuDisabled = false;
        if (skuIndex != -1) {
          this.skuIndex = skuIndex;
          //轮播图序号
          this.$nextTick(() => {
            this.bannerMouseOver(0);
          });

          var skus = this.skus[skuIndex];
          if (skus.lowestBuy > 0) {
            this.minPayNum = skus.lowestBuy;
            this.payNum = skus.lowestBuy;
          }
          if (skus.state == 0) {
            this.skuDisabled = true;
          }
        }
      },
      bannerChange(e) {
        this.bannerIndex = e;
        if (e == 8) {
          this.bannerNextScroll(500);
          this.bannerNext = true;
        }
        if (e == 0) {
          this.bannerNextScroll(-500);
          this.bannerNext = false;
        }
      },
      switchLike() {
        if (!this.getLogInState) {
          this.goLogin();
          return;
        }
        if (this.liked) {
          return;
        } else {
          this.collectLoading = true;
          this.$axios
            .post(
              "/api/VouchersGoods/AddFavourite",
              this.qs.stringify({
                GoodsId: this.goodsId,
                GoodsSkuId: this.skuId,
              })
            )
            .then((res) => {
              this.liked = true;
              this.notifySucceed("收藏成功");
              this.collectLoading = false;
            })
            .catch(() => {
              this.collectLoading = false;
            });
        }
      },
      goLogin() {
        this.setShowLogin(true);
      },
      putToCart() {
        if (this.sellout || !this.skuState) return;
        if (this.isPay) {
          this.$router.replace({ path: "/shoppingCart" });
          return;
        }
        // 提交购物车
        new Promise((resolve, reject) => {
          if (!this.getLogInState) {
            // this.notifyError("加入购物车失败，请登录~");
            this.goLogin();
          } else {
            this.btnLoading = true;
            const query = {
              UserID: this.getUserId,
              GoodID: this.goodsId,
              GoodsSkuId: this.skuId,
              numbers: this.payNum,
            };
            this.$axios
              .post("/api/Cart/AddToCart", this.qs.stringify(query))
              .then((res) => {
                if (res.data) {
                  const data = res.data;
                  const shoppingCart = {
                    id: data.Id, // 购物车id
                    productID: data.GoodsId, // 商品id
                    productName: data.Name, // 商品名称
                    productImg: data.Photo, // 商品图片
                    price: data.Price, // 商品价格
                    num: data.Numbers, // 商品数量
                    maxNum: 0, // 商品限购数量
                    minNum: this.minPayNum, //最小购买数量
                    check: true, // 是否勾选
                    skuId: data.GoodsSkuId,
                    skuName: data.SkuName,
                    goodsFromType: data.GoodsFromType,
                  };
                  this.unshiftShoppingCart(shoppingCart);
                  this.isPay = true;
                  this.btnName = "去购物车结算";
                  return resolve();
                } else {
                  // this.notifyError("加入购物车失败，请稍后重试~");
                  this.btnLoading = false;
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }
        }).then(() => {
          this.notifySucceed("成功加入购物车");
          setTimeout(() => {
            this.btnLoading = false;
          }, 1000);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login-notic {
    background-color: $bg-color-grey;
    width: 100%;
    height: 48px;
    line-height: 48px;
    @extend %flex-align-center;
    justify-content: center;
    .title {
      color: #333;
    }

    .btn-login {
      color: #ff6700;
      margin-left: 25px;
      cursor: pointer;
    }

    .icon {
      color: #b5b5b5;
      font-size: 12px;
      font-weight: bold;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .goods-wrapper {
    background-color: $bg-color;

    .wrap {
      width: $page-width;
      margin: 0 auto;
      display: flex;
      padding-top: 32px;

      .banner-wrap {
        width: 560px;
        height: 560px;
        position: relative;
        margin-right: 58px;
        .el-carousel {
          width: 560px;
        }
        .bannerImage {
          margin-top: 20px;
          .arrow-next,
          .arrow-prev {
            display: block;
            width: 22px;
            height: 32px;
            float: left;
            position: absolute;
            cursor: pointer;
            // top: 50%;
            margin-top: 10px;

          }
          .arrow-next {
            margin-left: 540px;
            background: url(//static.360buyimg.com/item/unite/1.0.137/components/default-soa/preview/i/disabled-next.png)
              no-repeat;
            &.disabled:hover i {
              background: url(//static.360buyimg.com/item/unite/1.0.137/components/default-soa/preview/i/hover-next.png)
                no-repeat;
            }
          }
          .arrow-prev {
            background: url(//static.360buyimg.com/item/unite/1.0.137/components/default-soa/preview/i/disabled-prev.png)
              no-repeat;
            &.disabled:hover i {
              background: url(//static.360buyimg.com/item/unite/1.0.137/components/default-soa/preview/i/hover-prev.png)
                no-repeat;
            }
          }
          .arrow-next i,
          .arrow-prev i {
            display: inline-block;
            width: 40px;
            height: 35px;
          }
          ul {
            position: absolute;
            width: 500px;
            height: 80px;
            overflow-x: auto;
            white-space: nowrap;
            margin-left: 30px;
          }
          .bannerli {
            margin-left: 10px;
            display: inline-block;
          }
          ul li img {
            border: 2px solid #fff;
            *padding-bottom: 1px;
            &.img-hover {
              border: 2px solid var(--ThemeColor,#ff6701);
            }
          }
          ::-webkit-scrollbar {
            width: 0px;
            /*height: 4px;*/
          }
        }
        .banner-swiper {
          height: 560px;
        }
        .cover {
          width: 560px;
          height: 560px;
          cursor: pointer;
        }

        .btn {
          position: absolute;
          top: 50%;
          width: 41px;
          height: 69px;
          transform: translateY(-50%);
          cursor: pointer;
          border-radius: 2px;
          @extend %flex-align-center;
          justify-content: center;

          &:hover {
            background-color: rgba(0, 0, 0, 0.6);
          }

          .icon {
            font-size: 30px;
            color: $border-color;
          }

          &.btn-left {
            left: 0;
          }

          &.btn-right {
            right: 0;
          }
        }

        ::v-deep .uni-swiper-dots {
          bottom: 0;

          .uni-swiper-dot {
            display: inline-block;
            width: 50px;
            height: 2.5px;
            margin-right: 10px;
            background-color: #ccc;
            cursor: pointer;
            border-radius: 0;

            &.uni-swiper-dot-active {
              background-color: #a3a3a3;
            }
          }
        }
      }

      .magnifier {
        z-index: 1;
        width: 300px;
        height: 300px;
        position: fixed;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #999;
        background-color: #ffffff;
      }

      .goods-main {
        position: relative;
        overflow: hidden;
        .angle-box {
          color: #fff;
          height: 30px;
          width: 150px;
          line-height: 40px;
          position: absolute;
          right: -100px;
          top: -50px;
          background-color: #67c23a;
          text-align: center;
          transform-origin: left top;
          transform: rotate(45deg);
          font-size: 12px;
          &.isjd {
            background-color: #ff5933;
          }
        }
        .goods-name {
          font-size: 16px;
          font-weight: normal;
          color: #212121;
          line-height: 30px;
          /* 文字最多显示两行，超出显示'...' */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          cursor:default;
          .product-tag {
            border-radius: 10px;
            background-color: #67c23a;
            color: #f5f5f5;
            padding: 0px 10px;
            font-size: 12px;
            margin: 0 10px;
            &.isjd {
              background-color: #ff5933;
            }
          }
        }

        .goods-desc {
          color: #b0b0b0;
          padding-top: 8px;
          font-size: 14px;
        }

        .goods-price {
          font-size: 18px;
          line-height: 1;
          color: $color-primary;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }

        .sku-list {
          margin-top: 40px;

          .sku-wrap {
            margin-bottom: 30px;

            .title {
              line-height: 1;
              font-size: 16px;
              color: #999;
            }

            .items {
              display: flex;
              flex-wrap: wrap;
              width: 612px;
              overflow-x: hidden;

              .item {
                height: 42px;
                width: 298px;
                line-height: 42px;
                margin-top: 15px;
                margin-right: 6px;
                cursor: pointer;
                font-size: 15px;
                color: #666;
                transition: all 0.2s ease;
                @extend %flex-align-center;
                //   justify-content: center;
                border: 1px solid $border-color;

                &.active {
                  border-color: var(--ThemeColor,#ff6701);

                  .name {
                    color: var(--ThemeColor,#ff6701);
                  }

                  .price {
                    color: #757575;
                  }
                }

                .name {
                  line-height: initial;
                  overflow: hidden;
                  text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号

                  display: -webkit-box;

                  -webkit-line-clamp: 2; // 设置两行文字溢出

                  -webkit-box-orient: vertical;
                  max-width:248px;
                  // max-width: 170px;
                  // color: $span-color;
                }

                .thumb {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                }

                .price {
                  color: #b0b0b0;
                  margin-left: 20px;
                }
              }
            }
          }
        }

        .current-goods {
          background: #f9f9fa;
          // padding: 30px;
          margin-bottom: 30px;

          .goods-info {
            @extend %flex-align-center;
            justify-content: space-between;
            line-height: 30px;
            color: #616161;

            .price-wrap {
              @extend %flex-align-center;

              .original {
                span-decoration: line-through;
                margin-left: 5px;
              }
            }
          }

          .subtotal {
            color: $color-primary;
            font-size: 24px;
            line-height: 40px;
            padding: 20px 20px;
            .address {
              span {
                color: #999999;
              }
              .text {
                border: 1px solid #DCDFE6;
                border-radius: 3px;
                color: #606266;
                cursor: pointer;
                padding: 5px 10px;
                i {
                  padding-left: 10px;
                  padding-right: 10px;
                }
              }
            }
            span {
              padding-left: 10px;
              font-size: 15px;
              color: black;
            }
            .price {
              width: 570px;
              height: 80px;
              .priceLeft {
                float: left;
                //margin-top:40px;
              }
              .priceRight {
                float: left;
                padding-left: 10px;
                .originalPrice {
                  font-size: 24px;
                  color: var(--ThemeColor,#ff6701);
                }
                .priceNum {
                  font-size: 20px;
                  color: #3a8ee6;
                  padding: 0px 0px;
                }
                .originalName {
                  border: 1px solid #3a8ee6;
                  border-radius: 5px;
                  background: #3a8ee6;
                  color: #f2f6fc;
                  padding: 0px 0px;
                  font-size: 15px;
                  padding: 2px 2px;
                }
              }
            }
            .dec {
              .item {
                .red_bg {
                  border: 1px solid #df3033;
                  color: #df3033;
                  text-align: center;
                  padding: 0 0;
                  margin-left: 10px;
                  font-size: 14px;
                }
                .text {
                  font-size: 14px;
                  color: #666;
                }
              }
            }
          }
        }

        .btn-wrap {
          @extend %flex-align-center;
          ::v-deep .addnum {
            margin-right: 20px;
            .el-input__inner {
              height: 54px;
            }
            span {
              height: 50%;
            }
            i {
              font-size: 18px;
              margin-top: 5px;
            }
          }
          .btn-primary {
            width: 300px;
            height: 54px;
            // line-height: 54px;
            font-size: 16px;
            color: #ffffff;
            // background-color: #ff6701;
            background-color: var(--ThemeColor,#ff6701);
            // text-align: center;
            margin: 0;

            &:hover {
              opacity: 0.8;
            }

            &.disabled {
              cursor: not-allowed;
              background-color: $bg-color-gray;
            }
          }

          .btn-gray {
            width: 142px;
            height: 54px;
            line-height: 54px;
            font-size: 16px;
            color: #ffffff;
            background-color: #b0b0b0;
            margin-left: 15px;
            @extend %flex-align-center;
            justify-content: center;
            position: relative;

            &:hover {
              background-color: #757575;
            }

            .icon {
              font-size: 22px;
              margin-right: 10px;
              margin-top: -2px;
              &.animation {
                position: absolute;
                left: 39px;
              }
            }

            &.active {
              .icon {
                color: #e53935;

                &.animation {
                  animation: zoom ease-out 1s;

                  @keyframes zoom {
                    0% {
                      transform: scale(1) translate(0%, 0%);
                      opacity: 1;
                      filter: alpha(opacity=100) \9;
                    }

                    10% {
                      transform: scale(1) translate(-30%, -30%);
                      opacity: 0.7;
                      filter: alpha(opacity=70) \9;
                    }

                    100% {
                      transform: scale(3) translate(-0.5%, -0.5%);
                      opacity: 0;
                      filter: alpha(opacity=0) \9;
                    }
                  }
                }
              }
            }
          }
        }
        .tip {
          margin-top: 20px;
          color: #ff0000;
        }
      }
    }
  }

  .price-desc {
    margin-top: 50px;
    background-color: $bg-color-grey;

    .wrap {
      width: $page-width;
      margin: 0 auto;
      padding-bottom: 50px;

      .title {
        font-size: 22px;
        font-weight: normal;
        padding: 1em 0;
      }

      .image {
        width: 100%;
        height: 189px;
      }
    }
  }

  .shipInfo 
  {
    color: #999999;
    font-size: x-small;
    padding-left: 10px;
  }
  .usually-address
  {
    color: #999;
    font-size: 14px;
    padding: 10px 0 10px 10px;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);
    background: #FFF;
    border: 1px solid #DCDFE6;
    border-bottom: 0;
  }
</style>
