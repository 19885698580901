<template>
  <div v-loading="loading" style="min-height: 300px" >
    <sku :goodsId="Number(details.id)" :goodInfo="goodInfo" :goodskuId="skuId"></sku>
    <!-- 导航栏 -->
    <el-tabs
      v-model="activeKey"
      type="card"
      @tab-click="handleClick"
      class="tabs"
      v-if="details.id"
      :class="{ fixed: scrollTop > 700 }"
    >
      <el-tab-pane label="商品详情" name="sketch"></el-tab-pane>
      <el-tab-pane
        v-if="jdParam.length > 0"
        label="规格与包装"
        name="attrs"
      ></el-tab-pane>
    </el-tabs>
    <!-- 参数 v-else-if="details.specs && activeKey == 'specs'"-->
    <!-- <div class="goods-specs" v-html="details.specs"></div> -->
    <!-- 概述  v-if="activeKey == 'sketch'"-->
    <div v-if="!loading && activeKey == 'sketch'">
      <div class="jd-attrs" v-if="jdCategoryAttrs.length > 0">
        <div class="attrsinfo">
          <div class="enterprise">
            品牌：<span>{{ goodInfo.enterprise }}</span>
          </div>
          <div class="attrs">
            <ul>
              <li
                v-for="(item, index) in jdCategoryAttrs"
                :key="index"
                :title="item.value"
              >
                <span>{{ item.name }}：</span>
                <span>{{ item.value }}</span>
              </li>
            </ul>
            <p @click="attrsClick" v-if="jdParam.length > 0">
              <a href="javascript:void(0);">更多参数<span class="txt-arr">&gt;&gt;</span></a>
            </p>
          </div>
        </div>
      </div>
      <div class="goods-sketch" v-if="details.sketchType == 0">
        <el-image
          v-for="(item, index) in details.sketch"
          :key="index"
          :src="item"
          lazy
        ></el-image>
      </div>
      <div class="goods-sketch" v-else v-html="details.specs"></div>
    </div>
    <!-- 规格 -->
    <div class="goods-param" v-else-if="!loading && jdParam.length>0">
      <el-row v-for="(item, index) in jdParam" :key="index" class="paramGroup">
        <el-col :span="4" class="groupName">{{ item.groupName }}</el-col>
        <el-col :span="20" class="atts">
          <el-row
            v-for="(items, indexs) in item.atts"
            :key="indexs"
            class="paramVals"
          >
            <el-col :span="4" class="attName">{{ items.attName }}</el-col>
            <el-col :span="20" class="vals">{{ items.vals.join(" ") }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- 评论 -->
    <!-- <comment v-else-if="activeKey == 'comment'" :goodsId="details.id" :scrollTop="scrollTop"></comment> -->
    <!-- 规格 -->
    <el-empty v-if="!goodInfo.id && !loading" :image-size="200" style="height:calc(100vh - 418px);" description="商品不存在或者已下架"></el-empty>
  </div>
  
  
</template>

<script>
// import comment from '@/components/goods/comment.vue';
import sku from "@/components/goods/sku.vue";

export default {
  components: {
    // comment,
    sku,
  },
  data() {
    return {
      navs: [
        {
          name: "详情",
          key: "sketch",
        },
        // {
        //   name: "参数",
        //   key: "specs",
        // },
        // {
        // 	name: '用户评价',
        // 	key: 'comment'
        // }
      ],
      activeKey: "sketch",
      details: {},
      scrollTop: 0,
      productID: 0,
      goodInfo: {},
      loading: true,
      jdCategoryAttrs: [],
      jdParam: "",
      isLook:true,
      skuId:0,
      search:""
    };
  },
  watch: {
    productID(e) {
      let routeSearch =this.search; 
      if(routeSearch == undefined || routeSearch == null || routeSearch == "" )
      {
        routeSearch = this.$route.query.search;
      }

      if(routeSearch != undefined && routeSearch != null && routeSearch != "")
      {
        this.skuId = Number(routeSearch);
      }

      if(e>0 || this.skuId>0){
         this.findGoodsDetail(e);
      }else{
         this.loading=false
        this.isLook=false
        
      }
    },
    skuId(e){
      if (e>0 ||this.productID >0) {
         this.findGoodsDetail(0);
      } else {
          this.loading=false
          this.isLook=false
      }
    },
    isLook(e){
      if(!e){
        //  this.$alert("当前商品不存在或已下架", "", {
        //   showCancelButton: true,
        //   confirmButtonText: "返回首页",
        //   cancelButtonText: "看看其它商品",
        //   closeOnClickModal: true,
        //   callback: (action, instance) => {
        //     if (action == "confirm") {
        //       console.log(action);
        //       this.$router.push({ path: "/" });
        //     } else {
        //       this.$router.go(-1);
        //     }
        //   },
        // });
      }
    }
  },
  activated() {
    if(this.$route.query.productID){
      this.productID = this.$route.query.productID;
    }
    if(this.$route.query.skuId){
      this.skuId=this.$route.query.skuId
    }
    this.activeKey='sketch'
    this.jdCategoryAttrs=[]
    this.jdParam=[]
    this.goodInfo={}
  },
  //离开当前组件前一定要清除滚动监听，否则进入其他路由会报错
  beforeRouteLeave(to, from, next) {
    this.productID=0
    this.skuId=0
    // window.removeEventListener("scroll", this.handleTabFix, true);
    next();
  },
  onPageScroll(e) {
    this.scrollTop = e.scrollTop;
  },
  methods: {
    attrsClick() {
      this.activeKey = "attrs";
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    handleTabFix() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
    findGoodsDetail(id, activeKey) {
      
      this.details = {};
      this.loading = true;
      this.isLook = true;
      // 判断该商品是否存在
      if (id > 0 || this.skuId>0) {
        this.$axios
          .get("/api/VouchersGoods/GetGoodDetail/GetGoodDetail", {
            params: {
              id: id,
              UserId: this.$store.getters.getUserId,
              SkuId:this.skuId
            },
          })
          .then((res) => {
            const data = res.data;
            if (!data) {
              this.isLook = false;
              this.loading = false;
              return;
            }
            if(id==0){
              id=data.Id
            }
            let skuList = [];
            data.Skus.forEach((item) => {
              const mode = {
                id: item.Id,
                price: item.Price,
                sellout: item.Inventory,
                state:item.State,
                lowestBuy:item.LowestBuy,
                icon:item.Photo,
                limitState:item.LimitState,
                title:item.Title,
                modelCodeNumber:item.ModelCodeNumber,
                imgsPath:item.ImgsPath,
                onlinePrice:item.JdOnlinePrice,
                goodsAttr: [
                  {
                    key: "",
                    value: item.Name,
                  },
                ],
              };
              skuList.push(mode);
            });
            const bannes = data.Photo.split(",");
            this.goodInfo = {
              id: data.Id,
              name: data.Name,
              introduction: data.Description,
              list: skuList,
              banners: bannes,
              isLike: res.itemCount,
              enterprise: data.Enterprise,
              goodsFromType:data.SourceId
            };
            const details = {
              sketchType: 1,
              id: id,
              sketch: [],
              specs: data.Details,
            };
            this.details = details;
            this.loading = false;
            this.jdCategoryAttrs = [];
            if (data.JdCategoryAttrs) {
              const categoryAttrs = JSON.parse(data.JdCategoryAttrs);
              categoryAttrs.forEach((item) => {
                const value = item.vals.join("，");
                this.jdCategoryAttrs.push({
                  name: item.attrName,
                  value: value,
                });
              });
            }
            if (data.JdParam) {
              this.jdParam = JSON.parse(data.JdParam);
            }
            this.$nextTick(() => {
              window.addEventListener("scroll", this.handleTabFix, true);
            });
            this.$nextTick(() => {
              document.documentElement.scrollTop = 0;
            });
          }).catch((err)=>{
            this.loading = false;
            this.isLook = false 
            return Promise.reject(err);
          });
      } else {
        this.isLook = false;
      }  
     
    },
    switchNav(key) {
      if (key != this.activeKey) {
        this.activeKey = key;

        // uni.pageScrollTo({
        // 	scrollTop: 0,
        // 	duration: 0
        // });
      }
    },
  },
};
</script>

<style lang="scss"  >
@import "@/common/styles/simditor";
.tabs {
  width: $page-width;
  margin: 100px auto;
  margin-bottom: 0px;
  background-color: #f7f7f7;
  z-index: 1;
  @extend %flex-align-center;
  border-bottom: 1px solid var(--ThemeColor,#ff6701);
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    animation: open 0.5s forwards;

    @keyframes open {
      from {
        margin-top: -50%;
      }

      to {
        margin-top: 0;
      }
    }
  }
  .is-top .is-active {
    // background-color: #ff6701;
    background-color:var(--ThemeColor,#ff6701);
    color: #fff;
  }
  .el-tabs__item.is-top:hover{
    color:var(--ThemeColor,#ff6701);
  }
  .el-tabs__header .el-tabs__item.is-active {
    // border-bottom-color: #ff6701;
    border-bottom-color:var(--ThemeColor,#ff6701);
  }
  .el-tabs__header .el-tabs__item.is-active:hover {
    // border-bottom-color: #ff6701;
    color: #fff;
  }

  .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0;
    border-bottom: none;
    .el-tabs__nav {
      border: none;
    }
  }
}

.goods-sketch {
  max-width: $page-width;
  width: 86%;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  overflow: auto;
  ::v-deep img {
    width: 100%;
    margin-top: -4px;
    object-fit: cover;
  }
  & ul {
    text-align: initial;
  }
}
.goods-param {
  max-width: 1224px;
  margin: 50px auto;
  color: #999;
  font-size: 14px;
  .paramGroup{
    padding-left: 120px;
    margin: 20px auto;
    border-bottom: 1px solid #eee;
    line-height: 220%;
    padding-bottom:20px;
  }
  .groupName {
    text-align: center;
  }
}
.goods-specs {
  width: $page-width;
  margin: 0 auto;
  margin-top: 10px;

  // /deep/ img {
  // 	max-width: 100%;
  // 	object-fit: cover;
  // }
}
.jd-attrs {
  margin: 40px auto;
  max-width: 1226px;
  color: #666;
  border-bottom: 1px solid #e0e0e0;
  font-size:14px;
  .attrsinfo {
    margin-left: 60px;
    // .enterprise{
    //   span{
    //     color: #005aa0;
    //   }
    // }
    & ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 20px;
    }
    & li {
      display: inline;
      float: left;
      flex: auto;
      width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 40px;
      padding-bottom: 10px;
    }
    & p {
      text-align: right;
      color: #005aa0;
      padding: 10px initial;
      margin: 20px auto;
    }
  }
}
</style>
